import styled from "styled-components"
import { base } from "./ColorStyles.js"
import { Link } from "gatsby"

export const H1 = styled.h1`
  font-family: "Lexend", sans-serif;
  font-family: "Geist", sans-serif;
  font-family: "Inter", sans-serif;

  text-align: left;
  margin: 0;
  font-size: 1.5rem;
  line-height: 120%;
  font-weight: 550;
  max-width: 1200px;
  color: ${base.primarytext};
  @media (max-width: 656px) {
    font-size: 1.5rem;
  }
`
export const H2 = styled.h2`
  font-family: "Lexend", sans-serif;
  font-weight: 600;
  font-size: 3rem;
  line-height: 120%;
  padding-top: 2rem;
  color: ${base.white};
  @media (max-width: 656px) {
    font-size: 1.75rem;
    margin-top: 1.25rem;
  }
`

export const H3 = styled.h3`
  font-family: "Lexend", sans-serif;
  font-family: "Geist", sans-serif;
    font-family: "Inter", sans-serif;

  font-size: 1.25rem;
  font-weight: 600;
  line-height: 140%;  
  margin: 0;
  color: ${base.primarytext};
  @media (max-width: 656px) {
    font-size: 1.25rem;
  }
`
export const H4 = styled.h4`
  font-family: "Lexend", sans-serif;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 150%;
  letter-spacing: 0.01em;
  margin: 0;
  color: ${base.primarytext};
  @media (max-width: 656px) {
    font-size: 0.875rem;
  }
`

export const H5 = styled.h5`
  font-family: "Geist Mono", monospace;
  font-family: "Inter", sans-serif;
  font-size: 0.875rem;
  font-weight: 500;
  margin: 0;
  line-height: 120%;
  color: ${base.secondarytext};
  @media (max-width: 656px) {
     font-size: 0.875rem;
  }
`

export const H6 = styled.h6`
  font-family: "Geist", sans-serif;
  font-family: "Inter", sans-serif;

  font-size: 0.875rem;
  line-height: 140%;
  font-weight: 600;
  letter-spacing: 0.03em;
  color: ${base.primarytext};
  @media (max-width: 656px) {
    font-size: 0.875rem;
  }
`

export const P = styled.p`
  /* font-family: "Karla", sans-serif; */
  font-family: "Hanken Grotesk", sans-serif;
  font-family: "Geist", sans-serif;
  /* font-family: "Inter", sans-serif; */

  font-optical-sizing: auto;
  font-weight: 400;
  font-size: 1rem;
  line-height: 24px;
  margin: 0;
  margin-top: 0.25rem;
  margin-bottom: 0.75rem;
  color: ${base.secondarytext};
  @media (max-width: 656px) {
    font-size: 0.875rem;
  }
`

export const PStrong = styled.p`
  font-weight: 600;
  font-size: 1rem;
  line-height: 120%;
  letter-spacing: 0.002em;
  margin: 0;
  color: ${base.primarytext};
  @media (max-width: 656px) {
    font-size: 1rem;
  }
`

export const Pmono = styled.p`
  font-family: "Spline Sans Mono", monospace;
  font-family: "Geist Mono", monospace;
  line-height: 140%;
  font-size: 0.8125rem;
  letter-spacing: 0.02em;
  margin: 0;
  /* margin-top: 0.25rem; */
  margin-bottom: 0.25rem;
  color: ${base.tertiarytext};
`

export const PmonoMini = styled(Pmono)`
  font-size: 0.8125rem;
  margin: 0;
  color: ${base.secondarytext};
`


export const PS = styled(P)`
  font-size: 0.875rem;
  color: ${base.secondarytext};
  line-height: 150%;
  margin: 0;
  margin-top: 0.125rem;

  @media (max-width: 650px) {
    font-size: 0.875rem;
  }
`

export const B = styled.b`
  font-weight: 600;
  line-height: 150%;
  color: ${base.primarytext};
  @media (max-width: 656px) {
    font-size: 1rem;
  }
`

export const SpanTitle = styled.span`
  width: 80px;
  display: inline-block;
  font-family: "Geist Mono", monospace;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.03em;
  margin: 0;
  line-height: 140%;
  color: ${base.tertiarytext};
  @media (max-width: 656px) {
    font-size: 0.875rem;
  }
`


export const Li = styled.li`
  font-size: 1rem;
  line-height: 150%;
  color: ${base.primarytext};
  list-style-type: disc;
  list-style-position: outside;
  margin: 0 1rem;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;

  @media (max-width: 656px) {
    font-size: 1rem;
  }
`

export const StyledLink = styled(Link)`
  font-family: "Inter", sans-serif;
  font-family: 'Instrument Sans', sans-serif;
  display: inline-block;
  margin-top: 1.5rem;
  font-size: 1rem;
  font-weight: 600;
  text-decoration: none;
  padding: 0.75rem 1.5rem;
  border-radius: 2px;
  color: ${base.primarytext};
  background: ${base.white};
  border: 1px solid ${base.lightgray};
  transition: all 0.25s ease-out;
 
  ::visited {
    color: ${base.darkblue};
  }
  :hover {
    text-decoration: none;
    color: ${base.white};
    background: ${base.blue};
    border: 1px solid ${base.dawn};
    transition: all 0.15s ease-in-out;
  }
  @media (max-width: 650px) {
    font-size: 1rem;
  }
`

export const WorkLink = styled(Link)`
  font-family: "Lexend", sans-serif;
  font-weight: 500;
  line-height: 130%;
  font-size: 1rem;
  line-height: 150%;
  /* padding: 0 2px 0 0; */
  margin: 0;
  display: inline-block;
  margin-right: 8px;
  text-decoration: none;
  width: fit-content;
  color: ${base.actionactive};

  /* border-bottom: 1px dashed ${base.background}; */
  transition: all 0.35s ease-out;
 
  ::visited {
    color: ${base.blue};
  }
  :hover {
    text-decoration: none;
    color: ${base.actionactive};
    transition: all 0.15s ease-in-out;
  }
  @media (max-width: 650px) {
    font-size: 1rem;
  }
`

export const A = styled.a`
  text-decoration: none;
  font-size: 1rem;
  padding: 0;
  border-radius: 2px;
  font-weight: 450;
  color: ${base.actionactive};
  transition: all 0.15s ease-out;
  ::visited {
    color: ${base.actionactive};
  }
  :hover {
    color: ${base.actionactive};
    background: ${base.hover};
    text-decoration: none;
    transition: all 0.25s ease-in-out;
  }
  @media (max-width: 650px) {
    font-size: 1rem;
  }
`

export const LinkButton = styled.a`
  font-size: 1rem;
  line-height: 18px;
  text-decoration: none;
  padding: 0.75rem 1.5rem;
  border-radius: 1px;
  color: ${base.blue};
  background: ${base.white};
  border: 1px solid ${base.blue} ;
  transition: all 0.25s ease-out;
  max-height: 44px;
 
  ::visited {
    color: ${base.darkblue};
  }
  :hover {
    text-decoration: none;
    color: ${base.white};
    background: ${base.blue};
    border: 1px solid ${base.darkblue};
    box-shadow: inset -2px -4px ${base.darkblue};
    transition: all 0.15s ease-in-out;
  }
  @media (max-width: 650px) {
    font-size: 1rem;
  }
`

export const A20 = styled.a`
 font-weight: 400;
  font-size: 1.125rem;
  line-height: 140%;
  text-decoration: none;
  width: fit-content;
  color: ${base.white};
  transition: all 0.15s ease-in-out;

  :hover {
    transition: all 0.2s ease-in-out;
    color: ${base.white};
  }
  @media (max-width: 650px) {
    font-size: 1rem;
  }
`

export const AFooter = styled(A20)`
  font-size: 0.875rem;
  font-weight: 400;
  
  color: ${base.secondarytext};

  :hover {
    color: ${base.actionactive};
    text-decoration: none;
  }
`

export const SectionBreak = styled.hr`
  border-top-width: 1px;
  --border-opacity: 1;
  border-color: rgba(250, 251, 252, 0.2);
  border-top: 1px rgba(250, 251, 252, 0.1);
  margin-top: 4rem;
  margin-bottom: 4rem;
`

export const HR = styled.hr`
  border-top-width: 1px;
  --border-opacity: 1;
  border-color: rgba(250, 251, 252, 0.2);
  border-top: 1px rgba(250, 251, 252, 0.1);
  margin-top: 3rem;
  margin-bottom: 3rem;
`

export const HRSmall = styled.hr`
  border-top-width: 1px;
  --border-opacity: 1;
  border-color: rgba(250, 251, 252, 0.2);
  border-top: 1px rgba(250, 251, 252, 0.1);
  margin-top: 3rem;
  margin-bottom: 3rem;
`
