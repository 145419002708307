import Seo from "../../../../src/components/seo";
import VideoPlayer from "../../../../src/components/post/video";
import TestComponent from "../../../../src/components/post/TestComponent";
import { Space24 } from "../../../../src/components/styles/Primitives";
import * as React from 'react';
export default {
  Seo,
  VideoPlayer,
  TestComponent,
  Space24,
  React
};